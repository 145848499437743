import { useEffect, useState, useCallback } from "react";
import Container from "@mui/material/Container";
import Game from "./Game";
import InitFriendGame from "./InitFriendGame";
import ChooseGame from "./ChooseGame";
import CustomDialog from "./components/CustomDialog";
import socket from "./socket";
import { TextField } from "@mui/material";
import bgImage from './shtei.jpg';

export default function App() {
  const [username, setUsername] = useState("");
  const [usernameSubmitted, setUsernameSubmitted] = useState(false);
  const [setupDialogOpen, setSetupDialogOpen] = useState(true);

  const [room, setRoom] = useState("");
  const [orientation, setOrientation] = useState("white");
  const [players, setPlayers] = useState([]);
  const [playFriend, setFriend] = useState(false);
  const [playRandom, setRandomMoves] = useState(false);

  // resets the states responsible for initializing a game
  const cleanup = useCallback(() => {
    setRoom("");
    setOrientation("");
    setPlayers("");
  }, []);

  useEffect(() => {
    // const username = prompt("Username");
    // setUsername(username);
    // socket.emit("username", username);

    socket.on("opponentJoined", (roomData) => {
      console.log("roomData", roomData)
      setPlayers(roomData.players);
    });
  }, []);

  return (
    <Container
    style={
      {
        // backgroundColor: 'black',
        // width: '100%',
      }
    }
    >
      {/* <Container
    class="blackBackground" style={
      {
        background: `rgba(0, 0, 0, 0.1) url(${bgImage}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        width: '100%',
      }
    }> */}
      
      <CustomDialog
        open={!usernameSubmitted}
        handleClose={() => {
          setUsernameSubmitted(true);
          setSetupDialogOpen(true);
        }}
        title="Pick a username"
        contentText="Please select a username"
        handleContinue={() => {
          if (!username) return;
          socket.emit("username", username);
          setUsernameSubmitted(true);
          setSetupDialogOpen(true);
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          id="username"
          label="Username"
          name="username"
          value={username}
          required
          onChange={(e) => setUsername(e.target.value)}
          type="text"
          fullWidth
          variant="standard"
        />
      </CustomDialog>
      {
       ((playFriend && room) || (!playFriend))&&
        <Game
          room={room}
          orientation={orientation}
          username={username}
          players={players}
          // the cleanup function will be used by Game to reset the state when a game is over
          cleanup={cleanup}
          playFriend={playFriend}
          playRandom={playRandom}
        />
      }
      
      {(playFriend) &&
      <InitFriendGame
        setRoom={setRoom}
        setOrientation={setOrientation}
        setPlayers={setPlayers}
      />}
      {/* {useEffect(() => { */}
        <ChooseGame
        openSetupDialog={setupDialogOpen}
        setRoom={setRoom}
        setOrientation={setOrientation}
        setPlayers={setPlayers}
        setRandomMoves={setRandomMoves}
        setFriend={setFriend}
      />
      {/* }, [setupDialogOpen])} */}
      
    </Container>
    // </Container>
  );
}