import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import bK from "../media/bK.svg";
import wK from "../media/wK.svg";
import wbK from "../media/wbK.svg";

export default function SetupDialog({ open, handleSandbox, handleRandom, handleFriend }) {
  return (
    <Dialog open={open}> {/*dialog container*/}
      <h2 style={{textAlign: 'center'}}>Start Game</h2>
      
      <DialogActions> {/* Dialog action buttons */}
        {/* Force users to make input without option to cancel */}
        {/* <Button onClick={handleClose}>Cancel</Button> */}
        <Button onClick={() => {
            handleFriend();
        }}>Play with <br></br>Friend</Button>
        <Button onClick={handleRandom}>Play against <br></br>Random</Button>
        <Button onClick={handleSandbox}>Sandbox <br></br>Mode</Button>
      </DialogActions>
    </Dialog>
  );
}