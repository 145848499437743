import { Button, Stack, TextField, Grid } from "@mui/material";
import { useState } from "react";
import SetupDialog from "./components/SetupDialog";
import socket from './socket';
import InitFriendGame from "./InitFriendGame";

export default function ChooseGame({ openSetupDialog, setRoom, setOrientation, setPlayers, setRandomMoves, setFriend }) {
const [openSetup, setOpenSetup] = useState(openSetupDialog);
    //   const [selectedGame, setSelectedGame] = useState(openSetupDialog);
//   const [roomInput, setRoomInput] = useState(''); // input state
//   const [roomError, setRoomError] = useState('');

  return (
    <>
    <SetupDialog
        open={Boolean(openSetup)}
        handleClose={
          () => setOpenSetup(false)
          }
        handleSandbox={()=>{
          setOpenSetup(false);
          setRandomMoves(false);
          setFriend(false);
        }}
        handleRandom={()=>{
          setOpenSetup(false);
          setRandomMoves(true);
          setFriend(false);
        }}
        handleFriend={()=>{
          setOpenSetup(false);
          setRandomMoves(false);
          setFriend(true);
        //   <InitFriendGame
        //   setRoom={setRoom}
        //   setOrientation={setOrientation}
        //   setPlayers={setPlayers}
        //  />
        }}
        >
    </SetupDialog>
    </>
  );
}