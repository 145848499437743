import socket from "./socket";
import { useState, useMemo, useCallback, useEffect } from "react";
import { Chess, SQUARES, Square} from "./shtei.js/src/chess";
import { Chessboard } from "./react-shteiboard/src";
import { Piece, PromotionPieceOption } from "./react-shteiboard/src/chessboard/types";
import circleSvg from './circle.svg';
import CustomDialog from "./components/CustomDialog";
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { text } from "stream/consumers";

function Game({ players, room, orientation, cleanup, playRandom, playFriend }: any) {
  
  const [fen, setFen] = useState('r3Kq3r/2mbnnbm2/pppppppppp/10/10/10/10/PPPPPPPPPP/2MBNNBM2/R3kQ3R w - 0 1');
  // const [fen, setFen] = useState('r3Kq3r/2mbnnbm2/ppppQppppp/10/10/8P1/10/PPPPPPPP1P/2MBNNBM2/R3k4R w - 1 5');
  // const [game, setGame] = useState(new Chess(fen));
  const game = useMemo(() => new Chess(fen), []);
  const [optionSquares, setOptionSquares] = useState({});
  const [over, setOver] = useState("");
  const [userMoveFrom, setUserMoveFrom] = useState("");
  const [userMoveTo, setUserMoveTo] = useState<Square | null>(null);
  const [moveFromToStyles, setMoveFromToStyles] = useState({} as any);
  const [showPromotionDialog, setShowPromotionDialog] = useState(false);
  // const [moveSquares, setMoveSquares] = useState({});
  const [rightClickedSquares, setRightClickedSquares] = useState({} as any);




  // function makeAMove(move: string | { from: string; to: string; promotion?: string }) {
  //   let gameCopy = game as Chess;
  //   // gameCopy.move(move);
  //   // console.log("gamecopy",gameCopy);
  //   const result = gameCopy.move(move);
  //   // console.log("result:",result);
  //   // console.log("Chess object:",game);
  //   // const result = gameCopy.move(move);
  //   if (result) {
  //     // console.log("Successful move:",move);
  //     // console.log("Success fen:",game.fen());
  //     setGame(gameCopy);
  //     setFen(gameCopy.fen());
  //     setOptionSquares({});
  //     setRightClickedSquares({});
  //     setMoveTo(null);
  //     setMoveFrom("");
  //     setMoveSquares({});

  //     // setKing
  //   }
    
  //   return result; // null if the move was illegal, the move object if the move was legal
  // }

  const makeAMove = useCallback(
    (move: any) => {
      try {
        const result = game.move(move); // update Chess instance
        setFen(game.fen()); // update fen state to trigger a re-render
  
        // console.log("over, checkmate", game.isGameOver(), game.isCheckmate());
  
        if (game.isGameOver()) { // check if move led to "game over"
          if (game.isCheckmate()) { // if reason for game over is a checkmate
            // Set message to checkmate. 
            setOver(
              `Checkmate! ${game.turn() === "w" ? "Black" : "White"} wins!`
            ); 
            // The winner is determined by checking which side made the last move
          } else if (game.isDraw()) { // if it is a draw
            setOver("Draw"); // set message to "Draw"
          } else {
            setOver("Game over");
          }
        }
        if (result) {
          const newSquares = {} as any;
          newSquares[move.to] = {
            backgroundColor: "rgba(225, 225, 0, 0.4)",
          };
          newSquares[move.from] = {
            backgroundColor: "rgba(225, 225, 0, 0.4)",
          };
          setMoveFromToStyles(newSquares);
        }
        
        return result;
      } catch (e) {
        return null;
      } // null if the move was illegal, the move object if the move was legal
    },
    [game]
  );

  useEffect(() => {
    if (playFriend) {
      socket.on('playerDisconnected', (player) => {
        setOver(`${player.username} has disconnected`); // set game over
      });
    }
  }, [playFriend]);
  useEffect(() => {
    if (playFriend) {
      socket.on('closeRoom', ({ roomId }) => {
      if (roomId === room) {
        cleanup();
      }
      });
    }
  }, [room, cleanup, playFriend]);
  useEffect(() => {
    if (playFriend) {
      playFriend && socket.on("move", (move) => {
        makeAMove(move); //
      });
    }
  }, [makeAMove, playFriend]);

 

  function makeRandomMove() {
    const possibleMoves = game.moves({verbose: true });
    // console.log(game.moves({verbose: true, xray: true}));
    // console.log(possibleMoves);
    if (game.isGameOver()) { // check if move led to "game over"
      if (game.isCheckmate()) { // if reason for game over is a checkmate
        // Set message to checkmate. 
        setOver(
          `Checkmate! ${game.turn() === "w" ? "Black" : "White"} wins!`
        ); 
        // The winner is determined by checking which side made the last move
      } else if (game.isDraw()) { // if it is a draw
        setOver("Draw"); // set message to "Draw"
      } else {
        setOver("Game over");
      }
      return;
    }

    const randomIndex = Math.floor(Math.random() * possibleMoves.length);
    // for (let i=0; i<possibleMoves.length; i++) {
    //   setTimeout(() => {
    //     const move = makeAMove({
    //       from: possibleMoves[i].from,
    //       to: possibleMoves[i].to,
    //     });
    //     game.undo();
    //     //setGame(new Chess(game.fen()));
    //   }, 5000);
      
    // }
    const move = makeAMove({
      from: possibleMoves[randomIndex].from,
      to: possibleMoves[randomIndex].to,
      // promotion: "q",
    });

    // console.log("Computer plays: " + possibleMoves[randomIndex].from + " to " + possibleMoves[randomIndex].to);
  }

  function onDrop(sourceSquare: Square, targetSquare: Square, piece: Piece) {
    // orientation is either 'white' or 'black'. game.turn() returns 'w' or 'b'
    if ((playFriend || playRandom) && game.turn() !== orientation[0]) return false; // <- 1 prohibit player from moving piece of other player

    if (game.isGameOver() || over !== "") return false;

    if (playFriend && players.length < 2) return false; // <- 2 disallow a move if the opponent has not joined

    const move = makeAMove({
      from: sourceSquare,
      to: targetSquare,
      //promotion: "q", 
    });
    
    // illegal move
    if (move === null) return false;
    setMoveFromToStyles({});
    // let gameCopy = game;
    // gameCopy.move(move);
    // // console.log("is gameover?: " + gameCopy.isGameOver());
    // if (game.isGameOver()) {
    //   console.log("GAME OVER");
    //   return true;
    // }

    if (playFriend) {
      socket.emit("move", { // <- 3 emit a move event.
        move,
        room,
      }); // this event will be transmitted to the opponent via the server
    }

    const newSquares = {} as any;
    // console.log("move to:", move.to);
    // console.log("move from:", move.from);
    newSquares[move.to] = {
      background: "rgba(225, 225, 0, 0.4)",
    };
    newSquares[move.from] = {
      background: "rgba(225, 225, 0, 0.4)",
    };
    setMoveFromToStyles(newSquares);


    // setMoveFrom(move.from);
    // setMoveTo(move.to);
    // console.log("ondrop possible moves comp: ", game.moves({verbose: true, xray: false}));
    // setTimeout(makeRandomMove, 200);
    // setFen(game.fen());
    if (playRandom) {
      setTimeout(makeRandomMove, 300);
    }

    return true;
  }

  // function onPromote(promoteFromSquare?: Square | undefined, promoteToSquare?: Square | undefined, piece?: PromotionPieceOption) {
  //   if (game.isGameOver() || over !== "") return false;
  //   if (piece && promoteFromSquare && promoteToSquare) {
  //     const move = makeAMove({
  //       from: promoteFromSquare,
  //       to: promoteToSquare,
  //       promotion: piece?.charAt(1).toLowerCase()});
  //     // illegal move
  //     if (move === null) return false;
      
  //     // setTimeout(makeRandomMove, 200);
  //     return true;
  //   }
    
  //   return false;
  // }
  // function onPromoteCheck(sourceSquare: Square, targetSquare: Square, piece: Piece) {
  //   console.log("piece: ", piece);
  //   return true;
  // }  
  function getMoveOptions(square: Square) {
    const gameCopy = game as Chess;
    const moves = gameCopy.moves({
      square,
      verbose: true,
    });
    if (moves.length === 0) {
      setOptionSquares({});
      return false;
    }
  
    const newSquares = {} as any;
    moves.map((move) => {
      newSquares[move.to] = {
        background:
          game.get(move.to)
          // && game.get(move.to).color !== game.get(square).color
            ? (moveFromToStyles[move.to] ? "radial-gradient(circle, rgba(186, 177, 0, 0.6) 70%, rgba(225, 225, 0, 0.6) 70%)" : "radial-gradient(circle, rgba(0,0,0,.1) 70%, transparent 70%)") 
            : "radial-gradient(circle, rgba(0,0,0,.1) 25%, transparent 25%)",
        // borderRadius: "50%",
      };
      return move;
    });
    newSquares[square] = {
      background: "rgba(255, 210, 0, 0.6)",
    };
    setOptionSquares(newSquares);
    return true;
  }
  // function onClick(piece: Piece,square: Square, ): void {
  //   console.log("Clicked piece", piece, "on", square);
  //   getMoveOptions(square);
  //   // console.log(piece)
  //   // return true;
  // }
  function onSquareClick(square: Square) {
    
    
    // orientation is either 'white' or 'black'. game.turn() returns 'w' or 'b'
    if ((playFriend || playRandom) && game.turn() !== orientation[0]) return false; // <- 1 prohibit player from moving piece of other player
    if (game.isGameOver() || over !== "") return false;
    if (playFriend && players.length < 2) return false; // <- 2 disallow a move if the opponent has not joined

    // from square
    if (!userMoveFrom) {
      const hasMoveOptions = getMoveOptions(square);
      if (hasMoveOptions) setUserMoveFrom(square);
      return;
    }
    setRightClickedSquares({});
    // to square
    if (!userMoveTo) {
      // check if valid move before showing dialog
      const moves = game.moves({
        // square: moveFrom,
        verbose: true,
      });
      const foundMove = moves.find(
        (m) => m.from === userMoveFrom && m.to === square
      );
      // not a valid move
      if (!foundMove) {
        // check if clicked on new piece
        const hasMoveOptions = getMoveOptions(square);
        // if new piece, setMoveFrom, otherwise clear moveFrom
        setUserMoveFrom(hasMoveOptions ? square : "");
        return;
      }
      else if (game.isGameOver()) { // check if move led to "game over"
        if (game.isCheckmate()) { // if reason for game over is a checkmate
          // Set message to checkmate. 
          setOver(
            `Checkmate! ${game.turn() === "w" ? "Black" : "White"} wins!`
          ); 
          // The winner is determined by checking which side made the last move
        } else if (game.isDraw()) { // if it is a draw
          setOver("Draw"); // set message to "Draw"
        } else {
          setOver("Game over");
        }
      }

      // valid move
      setUserMoveTo(square);

      // if promotion move
      if (
        (foundMove.color === "w" &&
          foundMove.piece === "p" &&
          square.slice(1,3) === "10") ||
        (foundMove.color === "b" &&
          foundMove.piece === "p" &&
          square.slice(1,3) === "1")
      ) {
        console.log("Click Promotion");
        setShowPromotionDialog(true);
        // onPromote(moveFrom as Square,square);
        return;
      }      
      // is normal move
      const gameCopy = game as Chess;
      const move = gameCopy.move({
        from: userMoveFrom,
        to: square,
        // promotion: "q",
      });

      

      // if invalid, setMoveFrom and getMoveOptions
      if (move === null) {
        const hasMoveOptions = getMoveOptions(square);
        if (hasMoveOptions) setUserMoveFrom(square);
        return;
      }
      else if (game.isGameOver()) { // check if move led to "game over"
        if (game.isCheckmate()) { // if reason for game over is a checkmate
          // Set message to checkmate. 
          setOver(
            `Checkmate! ${game.turn() === "w" ? "Black" : "White"} wins!`
          ); 
          // The winner is determined by checking which side made the last move
        } else if (game.isDraw()) { // if it is a draw
          setOver("Draw"); // set message to "Draw"
        } else {
          setOver("Game over");
        }
      }
      setMoveFromToStyles({});
      
      if (playFriend) {
        socket.emit("move", { // <- 3 emit a move event.
          move,
          room,
        }); // this event will be transmitted to the opponent via the server
      }
      
      const newSquares = {} as any;
      // console.log("move to:", move.to);
      // console.log("move from:", move.from);
      newSquares[move.to] = {
        background: "rgba(225, 225, 0, 0.4)",
      };
      newSquares[move.from] = {
        background: "rgba(225, 225, 0, 0.4)",
      };
      setMoveFromToStyles(newSquares);

      // setGame(gameCopy);
      setFen(gameCopy.fen());

      setUserMoveFrom("");
      setUserMoveTo(null);
      setOptionSquares({});

      if (playRandom) {
        setTimeout(makeRandomMove, 300);
      }
      return;
    }
  }

  function onPromotionPieceSelect(promoteFromSquare?: Square | undefined, promoteToSquare?: Square | undefined, piece?: PromotionPieceOption) {
    // if no piece passed then user has cancelled dialog, don't make move and reset
    if (piece) {
      // clicking piece promotion
      const gameCopy = game as Chess;
      const moveClick = gameCopy.move({
        from: userMoveFrom,
        to: userMoveTo as string,
        promotion: piece[1].toLowerCase() ?? "g",
      });

      const gameCopyCopy = game as Chess;
      const moveDrag = gameCopyCopy.move({
        from: promoteFromSquare as string,
        to: promoteToSquare as string,
        promotion: piece[1].toLowerCase() ?? "g",
      });
      let move;
      if (moveClick) {
        move = moveClick;
      }
      else if (moveDrag) {
        move = moveDrag;
      }
      else {
        return false;
      }

      setMoveFromToStyles({});
      // setGame(gameCopy);
      if (playFriend) {
        socket.emit("move", { // <- 3 emit a move event.
          move,
          room,
        }); // this event will be transmitted to the opponent via the server
      }

      setFen(gameCopy.fen());

      if (playRandom) {
        setTimeout(makeRandomMove, 300);
      }  
    }

    setUserMoveFrom("");
    setUserMoveTo(null);
    setShowPromotionDialog(false);
    setOptionSquares({});
    return true;
  }

  function onSquareRightClick(square: Square) {
    // const colour = "rgba(109, 159, 88, 1)";
    // const background = 
    // `radial-gradient(circle, rgba(142,153,97,1) 0%, rgba(109,159,88,1) 100%)`;
    
    setRightClickedSquares({
      ...rightClickedSquares,
      [square]:
        rightClickedSquares[square] 
        // &&
        // rightClickedSquares[square].background === background
          ? undefined
          : { 
            backgroundImage: `url(${circleSvg})`,
          },
    });
  }

  return (
    <>
    <Stack>
    {<Card>
      <CardContent>
        {playFriend ? <Typography variant="h5">Room ID: {room}</Typography> : 
        <Typography align="center" variant="h4">Shtei </Typography>}
      </CardContent>
    </Card>}
    <Stack flexDirection="row" sx={{ pt: 2 }}>
      <div className="board"  style={{
        // maxWidth: 600,
        // maxHeight: 600,
        width: '100%',
        flexGrow: 1,
      }}>
      {/* Don't allow premoves in Sandbox Mode */}
      {/* { (playFriend || playRandom) ? 
        <Chessboard position={fen}
            boardOrientation={orientation}
            // boardWidth={}
            onPieceDrop={onDrop} 
            arePremovesAllowed={true}
            // onPromotionPieceSelect={onPromote} 
            promotionDialogVariant="default" 
            onSquareClick={onSquareClick} 
            // customSquareStyles={{...optionSquares, ...rightClickedSquares, ...kingControlSquares}} 
            customSquareStyles={{...moveFromToStyles, ...optionSquares, ...rightClickedSquares}}
            onSquareRightClick={onSquareRightClick}
            // promotionToSquare={userMoveTo}
            onPromotionPieceSelect={onPromotionPieceSelect}
            showPromotionDialog={showPromotionDialog}
            animationDuration={200} /> :
        <Chessboard position={fen}
            boardOrientation={orientation}
            // boardWidth={}
            onPieceDrop={onDrop} 
            // arePremovesAllowed={true}
            // onPromotionPieceSelect={onPromote} 
            promotionDialogVariant="default" 
            onSquareClick={onSquareClick} 
            // customSquareStyles={{...optionSquares, ...rightClickedSquares, ...kingControlSquares}} 
            customSquareStyles={{...moveFromToStyles, ...optionSquares, ...rightClickedSquares}}
            onSquareRightClick={onSquareRightClick}
            // promotionToSquare={userMoveTo}
            onPromotionPieceSelect={onPromotionPieceSelect}
            showPromotionDialog={showPromotionDialog}
            animationDuration={200} />
    } */}
     <Chessboard position={fen}
            boardOrientation={orientation}
            // boardWidth={}
            onPieceDrop={onDrop} 
            arePremovesAllowed={true}
            // onPromotionPieceSelect={onPromote} 
            promotionDialogVariant="default" 
            onSquareClick={onSquareClick} 
            // customSquareStyles={{...optionSquares, ...rightClickedSquares, ...kingControlSquares}} 
            customSquareStyles={{...moveFromToStyles, ...optionSquares, ...rightClickedSquares}}
            onSquareRightClick={onSquareRightClick}
            // promotionToSquare={userMoveTo}
            onPromotionPieceSelect={onPromotionPieceSelect}
            showPromotionDialog={showPromotionDialog}
            animationDuration={200} />
      </div>
      {players.length > 0 && playFriend && (
        <Box>
          <List>
            <ListSubheader>Players</ListSubheader>
            {players.map((p: any) => (
              <ListItem key={p.id}>
                <ListItemText primary={p.username} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Stack>
    <CustomDialog // Game Over CustomDialog
      open={Boolean(over)}
      children={""}
      title={over}
      contentText={over}
      handleContinue={() => {
        setOver("");
        if (playFriend) {
          socket.emit("closeRoom", { roomId: room });
          cleanup();
        }
      }}
    />
    </Stack>
    </>
  );
}

export default Game;